import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../Components/Button";
import { useEffect, useState } from "react";
import { dateFormatter } from "../../utils/helperFn";
import "../Site/site.css";
import "./sitePages.css";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { Popup } from "../../Components/Popup";
import { TextField } from "../../Components/TextField";
import { Loader } from "../../Components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { pageFn, websiteState } from "../../Redux/websiteSlice";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { CustomDropdown } from "../../Components/Dropdown/customDropdown";
import { NotifyUser } from "../../Components/Notification";
import { Sidebar } from "../../Components/Sidebar";
import { CodeDisplay } from "../../Components/Code";
import { Copy } from "../../Components/Copy";
import {
  createPage,
  deletePage,
  getAllPages,
  updatePage,
  getPage,
  handlePreview,
} from "../../Api/page";
import { IDTooltip } from "../../Components/Tooltip/Idtooltip";

export default function SitePages() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showPagePopup, setShowPagePopup] = useState(false);
  const [showCode, setShowCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState("");
  const [selectedPage, setSelectedPage] = useState("");
  const [popupType, setPopupType] = useState("");

  const { siteName } = useSelector(websiteState);

  const fetchPages = async () => {
    setLoading(true);
    const response = await getAllPages(id);
    setPages(response);
    setLoading(false);
  };

  useEffect(() => {
    fetchPages();
    // eslint-disable-next-line
  }, [id]);

  const handleEdit = (e, id) => {
    e.stopPropagation();
    const filterPage = pages.filter((obj) => obj.id === id);
    if (!filterPage[0]) {
      return;
    }
    setPopupType("update");
    setSelectedPage(filterPage[0]);
    setShowPagePopup(true);
  };

  const handleCode = (e, id) => {
    e.stopPropagation();
    const filterPage = pages.filter((obj) => obj.id === id);
    if (!filterPage[0]) {
      return;
    }
    setSelectedPage(filterPage[0]);
    setShowCode(true);
  };

  return (
    <>
      <Sidebar />
      <main className="site-page">
        {showPagePopup ? (
          <Popup
            children={
              <SitePageCreate
                setShowPagePopup={setShowPagePopup}
                fetchPages={fetchPages}
                selectedPage={selectedPage}
                popupType={popupType}
              />
            }
            popup={showPagePopup}
            setPopup={setShowPagePopup}
            title="New Page"
          />
        ) : (
          ""
        )}

        {showCode ? (
          <Popup
            children={
              <CodeGeneration
                setShowCode={setShowCode}
                selectedPage={selectedPage}
              />
            }
            popup={showCode}
            setPopup={setShowCode}
            title="Sample Code"
            className="integration-code-popup"
          />
        ) : (
          ""
        )}

        <>
          <section className="site-heading-container">
            <h1>
              <Button
                title={<KeyboardBackspaceOutlinedIcon />}
                className="navigate-btn"
                onClick={() => navigate("/site")}
              />
              {siteName}
            </h1>
            <Button
              title="Create Page"
              onClick={() => {
                setShowPagePopup(true);
                setPopupType("create");
              }}
            />
          </section>

          {pages && pages.length ? (
            <section className="pageList-container">
              {pages.map((obj) => {
                return (
                  <Page
                    obj={obj}
                    fetchPages={fetchPages}
                    handleEdit={handleEdit}
                    handleCode={handleCode}
                  />
                );
              })}
            </section>
          ) : pages && !loading ? (
            <section className="message-container">
              <p>No Pages Found</p>
            </section>
          ) : (
            <section className="message-container">
              <Loader />
            </section>
          )}
        </>
      </main>
    </>
  );
}

function Page({ obj, fetchPages, handleEdit, handleCode }) {
  const {
    metadata: { title },
    updatedAt,
    id,
  } = obj;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showOption, setShowOption] = useState(false);

  const { siteId: websiteId } = useSelector(websiteState);

  const handleClick = ({ id, title, type }) => {
    dispatch(pageFn({ pageName: title, pageId: id }));
    navigate(`/page/${id}?type=${type}`);
  };

  const handleMoreInfo = (e) => {
    e.stopPropagation();
    setShowOption(true);
  };

  const handleDelete = async (e, id) => {
    e.stopPropagation();

    const response = await deletePage({ pageId: id, websiteId });
    if (!response?.error) {
      fetchPages();
      NotifyUser({
        type: "success",
        message: `Page Deleted`,
      });
    }
  };

  const menu = [
    {
      title: "Edit",
      callBack: (e, id) => handleEdit(e, id),
    },
    {
      title: "Delete",
      callBack: (e, pageId) => handleDelete(e, pageId),
    },
    {
      title: "View Blocks",
      callBack: (e, id, title) => handleClick({ id, title, type: "block" }),
    },
    {
      title: "View Forms",
      callBack: (e, id, title) => handleClick({ id, title, type: "form" }),
    },
    {
      title: "View Code",
      callBack: (e, id) => handleCode(e, id),
    },
    // {
    //   title: "Preview",
    //   callBack: (e, id, title) => handlePreview({ pageId: id, websiteId }),
    // },
  ];

  return (
    <div key={id} className="ind-page-list">
      <section className="ind-page-list-detail-container">
        <p>{title} </p>
        <IDTooltip title="Page ID" value={id} />
      </section>
      <p className="ind-page-list-detail-date">
        {" "}
        {dateFormatter({
          date: updatedAt,
          format: "DD MMM YYYY, hh:mm a",
        })}
      </p>
      {/* <p>{status}</p> */}
      {/* <p>{id}</p> */}

      <button onClick={handleMoreInfo}>
        <MoreVertOutlinedIcon />
      </button>
      {showOption ? (
        <CustomDropdown
          className="custom-dropdown-container-page"
          showOption={showOption}
          setShowOption={setShowOption}
          menu={menu}
          id={id}
          pageName={title}
        />
      ) : (
        ""
      )}
    </div>
  );
}

function SitePageCreate({
  setShowPagePopup,
  fetchPages,
  selectedPage,
  popupType,
}) {
  const form = [
    {
      label: "Title",
      type: "text",
      variableName: "title",
    },
    {
      label: "Slug",
      type: "text",
      variableName: "slug",
    },
    {
      label: "Content Type",
      type: "text",
      variableName: "contentType",
    },
  ];

  const [input, setInput] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { siteId: websiteId } = useSelector(websiteState);

  const handleChange = (e) => {
    const { name, value } = e.target;

    let temp = { ...input };
    temp[name] = value;
    setInput({ ...temp });
  };

  const handleClick = async () => {
    for (let i in input) {
      if (!input[i]) {
        return;
      }
    }
    setError("");

    let temp = { ...input, status: "draft" };
    temp.slug = temp.slug.toLowerCase();

    setLoading(true);

    let response;
    if (popupType === "create") {
      response = await createPage({
        data: { metadata: { ...temp } },
        websiteId,
      });
    } else if (popupType === "update") {
      response = await updatePage({
        data: { metadata: { ...temp } },
        websiteId,
        pageId: selectedPage.id,
      });
    }

    setLoading(false);
    if (!response.error) {
      await fetchPages();
      setShowPagePopup(false);
      NotifyUser({
        type: "success",
        message: `Page ${popupType}d successfully`,
      });
    } else {
      setError(response.message);
    }
  };

  useEffect(() => {
    if (
      selectedPage &&
      Object.keys(selectedPage).length &&
      popupType === "update"
    ) {
      setInput(selectedPage.metadata);
    }
  }, [selectedPage, popupType]);

  return (
    <main>
      <form className="page-create-form">
        {form.map(({ label, type, variableName }) => {
          if (type === "text") {
            return (
              <div className="ind-page-create-field" key={variableName}>
                <label className="formLabel">{label}</label>
                <TextField
                  type={type}
                  onChange={handleChange}
                  name={variableName}
                  value={input[variableName]}
                />
              </div>
            );
          }
          return null;
        })}
        {error ? <p className="auth-error-message">{error}</p> : ""}

        <Button
          title={popupType === "create" ? "Create" : "Update"}
          type="button"
          className="site-create-btn"
          onClick={handleClick}
          loading={loading}
        />
      </form>
    </main>
  );
}

function CodeGeneration({ setShowCode, selectedPage }) {
  const { siteId: websiteId } = useSelector(websiteState);
  const { id } = selectedPage;
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");

  const getContent = () => {
    if (Object.keys(code).length) {
      const variables = code.variables.join(";");
      const result = code.mainCode + variables;
      return result;
    }
    return "";
  };

  useEffect(() => {
    const fetchCode = async () => {
      setLoading(true);
      const response = await getPage({ websiteId, pageId: id, type: "code" });
      try {
        if (response) {
          const splitResponse = response.split("###!!###");
          const mainCode = splitResponse[0];
          const variables = splitResponse[1]?.split(";");
          setCode({ mainCode, variables });
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
      setLoading(false);
    };
    fetchCode();
  }, [id, websiteId]);
  return (
    <main style={{ padding: "1rem" }}>
      {code ? (
        <>
          <Copy
            content={getContent()}
            iconId="code-copy-icon"
            btnClassName="copy-code-btn"
          />

          <CodeDisplay code={code.mainCode} />
          {code.variables && code.variables.length
            ? code.variables.map((item, i) => {
              return <CodeDisplay code={item} />;
            })
            : ""}
        </>
      ) : !code && !loading ? (
        <section className="message-container">
          <p>Code Generation Failed</p>
        </section>
      ) : (
        <section className="message-container">
          <Loader />
        </section>
      )}
    </main>
  );
}
