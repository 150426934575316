import { Button } from "../Button";
import "./popup.css";

export function ConfirmModal({ children, className, handleConfirm, loading, handleCancel }) {
    return (
        <main className="popupOverlay">
            <div className={`confirm-popup-container ${className}`}>

                {children}
                <div className="confirm-modal-btn-container">
                    <Button title="Confirm" className="delete-confirm-btn" onClick={handleConfirm} loading={loading} />
                    <Button title="Cancel" onClick={handleCancel} />
                </div>

            </div>
        </main>
    );
}
