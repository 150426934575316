import { useState, useEffect } from "react";
import { getAllAuthUsers } from "../../../../Api/Integration/auth";
import { Loader } from "../../../../Components/Loader";

export function UserList({ id }) {
  const [users, setUsers] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchSiteData = async () => {
    setLoading(true);
    const response = await getAllAuthUsers(id);

    setUsers(response.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchSiteData();
  }, []);

  const headings = users && users.length ? Object.keys(users[0]?.details) : [];
  return (
    <main className='auth-user-form-table'>
      <table className='user-details-table'>
        <thead>
          <tr>
            {headings && headings.length
              ? headings.map((item, i) => {
                return (
                  <th className='ind-auth-user-form-label ' key={i}>
                    {item}
                  </th>
                );
              })
              : ""}
          </tr>
        </thead>
        <tbody>
          {users && users.length
            ? users.map((item, index) => {
              return (
                <tr key={index}>
                  {headings.map((heading, i) => {
                    let value = item?.details?.[heading]
                      ? item?.details?.[heading]
                      : "";

                    return (
                      <td
                        className='ind-auth-user-form-value'
                        title={value}
                        key={i}
                      >
                        {value}
                      </td>
                    );
                  })}
                </tr>
              );
            })
            : ""}
          {/* </tr> */}
        </tbody>
      </table>
      {users && !users.length ? (
        <section className='auth-user-form-no-response-container'>
          No Response
        </section>
      ) : (
        ""
      )}
      {loading ? (
        <section className='auth-user-form-no-response-container'>
          <Loader />{" "}
        </section>
      ) : null}
    </main>
  );
}
