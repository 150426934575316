import { useSelector } from "react-redux"
import "./blocklist.css"
import { blockState } from "../../../Redux/blockSlice"
import { CloseOutlined } from "@mui/icons-material"
import { Button } from "../../../Components/Button"
import { useSearchParams } from "react-router-dom"

export default function BlockList({ handleClose }) {

    const blocks = useSelector(blockState)?.blocks
    const [searchParams, setSearchParams] = useSearchParams();
    const blockId = searchParams.get("blockid")
    const nestedblockid = searchParams.get("nestedblockid");

    const addQuery = (id, nestedId) => {
        searchParams.set('blockid', id)
        searchParams.set('nestedblockid', nestedId)
        setSearchParams(searchParams)
    }
    return <main className="block-list">
        <section className="site-heading-container">
            <h1>
                Block List
            </h1>
            <Button
                title={<CloseOutlined />}
                className="navigate-btn"
                onClick={handleClose}
            />
        </section>
        {blocks && blocks.length ? blocks.filter(block => !block.nested).map(block => {
            switch (block?.blockType) {
                case "list":
                    return <div className="ind-blocklist" key={block?.id}>
                        <button className={`blocklist-title ${block?.id === blockId ? "blockList-bg-color" : ""}`} onClick={() => addQuery(block?.id)}>
                            {block.blockName}
                        </button>
                        <div className="nested-blocklist">
                            {block?.blockData && block?.blockData.map((nestedBlock, i) => {
                                return <button key={nestedBlock?.id} onClick={() => addQuery(block?.id, nestedBlock?.listId)} className={`nested-block-list-item ${nestedBlock?.listId === nestedblockid ? "blockList-bg-color" : ""}`}>{block.blockName} {i + 1}</button>
                            })}
                        </div>
                    </div>
                default:
                    return <div className="ind-blocklist" key={block?.id}>
                        <button className={`blocklist-title ${block?.id === blockId ? "blockList-bg-color" : ""}`} onClick={() => addQuery(block?.id)}>
                            {block.blockName}
                        </button>
                    </div>
            }
        }) : null}
    </main>
}