import { Button } from "../../../Components/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  CommentField,
  TextField,
} from "../../../Components/TextField/index.js";
import { RichTextEditor } from "../../../Components/TextField/RichTextEditor";
import { currentListFn } from "../../../Redux/listSlice";
import { uuid } from "../../../utils/helperFn";
import { websiteState } from "../../../Redux/websiteSlice";
import { DeleteOutlined } from "@mui/icons-material";
import {
  deleteBlockFn,
  createBlockFn,
  updateBlockContentFn,
  blockState,
} from "../../../Redux/blockSlice";
import { Link } from "./Link";
import { NestedGroup } from "./NestedGroup";
import { NestedList } from "./NestedList";
import { ConfirmModal } from "../../../Components/Popup/ConfirmModal.js";
import { useState } from "react";

export function List({ block, setContentType, handleLibrary, nestedObj }) {
  const dispatch = useDispatch();
  const { pageId, siteId: websiteId } = useSelector(websiteState);
  const { blocks, loading } = useSelector(blockState);
  const { blockData: listDetails, blockName, id } = block;
  const [deletePopup, setDeletePopup] = useState(false);

  const handleAdd = (listId) => {
    dispatch(currentListFn({ list: { ...block }, listId }));
    setContentType("list");
    handleLibrary();
  };

  const handleListAdd = (block) => {
    const tempBlock = JSON.parse(JSON.stringify({ ...block }));
    const { blockType, id: blockId, blockData } = tempBlock;
    let obj = blockData[blockData.length - 1];

    let newObj = {};
    for (let i in obj) {
      if (typeof obj[i] === "object") {
        let id = uuid();
        newObj[id] = { ...obj[i], id, blockData: {} };
      }
    }
    newObj.id = uuid();

    dispatch(
      createBlockFn({
        data: {
          data: {
            ...newObj,
            listId: uuid(),
          },
          blockType: blockType,
          blockId,
        },
        pageId,
        websiteId,
      })
    );
  };

  const handleDelete = (listData) => {
    if (!nestedObj) {
      dispatch(deleteBlockFn({ blockId: listData, pageId, websiteId }));
    } else {
      let obj = { ...nestedObj, nestedId: listData, type: "nestedList" };
      dispatch(
        deleteBlockFn({ blockId: nestedObj?.blockId, pageId, websiteId, obj })
      );
    }
  };

  const handleChange = (e, id, listId) => {
    let { name, value } = e.target;
    const filteredBlock = blocks.filter(
      (blockObj) => blockObj?.id === block?.id
    );

    const tempArr = JSON.parse(
      JSON.stringify([...filteredBlock[0]?.blockData])
    );

    const index = tempArr.findIndex((obj) => obj.listId === listId);

    tempArr[index][id].blockData = { [name]: value };

    let obj = { ...block };
    obj.blockData = [...tempArr];

    dispatch(updateBlockContentFn(obj));
  };

  const getBlockValue = ({ listId, variableName, id }) => {
    const tempArr = JSON.parse(JSON.stringify([...listDetails]));
    let index = tempArr.findIndex((obj) => obj.listId === listId);
    let outerObj = tempArr[index];
    return outerObj?.[id]?.blockData?.[variableName];
  };

  return (
    <div
      className={`list-field-container ${nestedObj ? "nested-list-container" : ""
        }`}
    >
      <section className='formLabel list-label'>
        {blockName}{" "}
        <button onClick={() => setDeletePopup(true)}>
          <DeleteOutlined />
        </button>
        {deletePopup ? (
          <ConfirmModal
            loading={loading}
            children={
              <p className='confirm-delete-msg'>
                Are you sure you want to delete this block?
              </p>
            }
            setPopup={setDeletePopup}
            handleConfirm={() => handleDelete(id)}
            handleCancel={() => setDeletePopup(false)}
          />
        ) : null}
      </section>
      {listDetails && listDetails.length ? (
        listDetails.map((item, index) => {
          return (
            <>
              {Object.entries(item).map(([key, values]) => {
                // if (typeof values === "string") {
                //   return null;
                // }
                // if(nested && !nestedObj)
                // {
                //   return ''
                // }
                let { fieldName, fieldType, id, blockData } = values;
                if (fieldType === "textArea") {
                  return (
                    <div className='block-field' key={id}>
                      <label className='formLabel'>{fieldName}</label>
                      <CommentField
                        name={fieldName}
                        onChange={(e) => handleChange(e, id, item.listId)}
                        value={getBlockValue({
                          id,
                          variableName: fieldName,
                          listId: item.listId,
                        })}
                      />
                    </div>
                  );
                } else if (fieldType === "text") {
                  return (
                    <div className='block-field' key={id}>
                      <label className='formLabel'>{fieldName}</label>
                      <TextField
                        name={fieldName}
                        onChange={(e) => handleChange(e, id, item.listId)}
                        value={getBlockValue({
                          id,
                          variableName: fieldName,
                          listId: item.listId,
                        })}
                      />
                    </div>
                  );
                } else if (fieldType === "link") {
                  return (
                    <Link
                      label={fieldName}
                      value={getBlockValue({
                        id,
                        variableName: fieldName,
                        listId: item.listId,
                      })}
                      key={id}
                      onChange={(e) => handleChange(e, id, item.listId)}
                    />
                  );
                } else if (fieldType === "rich_text_editor") {
                  return (
                    <div className='block-field' key={id}>
                      <label className='formLabel'>{fieldName}</label>
                      <RichTextEditor
                        name={fieldName}
                        onChange={(e) => handleChange(e, id, item.listId)}
                        value={getBlockValue({
                          id,
                          variableName: fieldName,
                          listId: item.listId,
                        })}
                      />
                    </div>
                  );
                } else if (fieldType === "nestedList") {
                  return (
                    <NestedList
                      key={id}
                      setContentType={setContentType}
                      handleLibrary={handleLibrary}
                      nestedListId={blockData}
                      handleListAdd={handleListAdd}
                      nestedObj={{
                        listId: item.listId,
                        blockId: block.id,
                        fieldId: id,
                      }}
                    />
                  );
                } else if (fieldType === "nestedGroup") {
                  return (
                    <NestedGroup
                      key={id}
                      setContentType={setContentType}
                      handleLibrary={handleLibrary}
                      handleDelete={handleDelete}
                      nestedListId={blockData}
                      nestedObj={{ listId: item.listId, blockId: block.id }}
                    />
                  );
                } else {
                  return null;
                }
              })}
              <Button
                title='Insert Field'
                className='add-ind-list-field-btn'
                onClick={() => handleAdd(item.listId)}
              />
            </>
          );
        })
      ) : (
        <Button
          title='Insert Field'
          className='add-ind-list-field-btn'
          onClick={() => handleAdd(0)}
        />
      )}
      <Button
        title='Add List'
        className='add-list-field-btn'
        onClick={() => handleListAdd(block)}
      />
    </div>
  );
}
