import "./blockLibrary.css";
import { useState } from "react";
import { Button } from "../../../Components/Button";
import { CloseOutlined } from "@mui/icons-material";
import {
  CommentField,
  TextField,
} from "../../../Components/TextField/index.js";
import { Dropdown } from "../../../Components/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  blockState,
  createBlockFn,
  updateBlockFn,
} from "../../../Redux/blockSlice";
import { uuid } from "../../../utils/helperFn";
import { useEffect } from "react";
import { listState } from "../../../Redux/listSlice";
import { websiteState } from "../../../Redux/websiteSlice";

export function BlockLibrary({ handleLibrary, contentType = "block" }) {
  const form1 = [
    {
      label: "Block Name",
      type: "text",
      variableName: "blockName",
    },
    {
      label: "Block Type",
      type: "select",
      variableName: "blockType",
      options: [
        { label: "Text", value: "text" },
        { label: "Text Area", value: "textArea" },
        { label: "Link", value: "link" },
        { label: "Rich text editor", value: "rich_text_editor" },
        { label: "List", value: "list" },
        { label: "Group", value: "group" },
      ],
    },
    {
      label: "Block Description",
      type: "textArea",
      variableName: "blockDescription",
    },
  ];

  const form2 = [
    {
      label: "Field Name",
      type: "text",
      variableName: "fieldName",
    },
    {
      label: "Field Type",
      type: "select",
      variableName: "fieldType",
      options: [
        { label: "Text", value: "text" },
        { label: "Text Area", value: "textArea" },
        { label: "Link", value: "link" },
        { label: "Rich text editor", value: "rich_text_editor" },
        { label: "Group", value: "nestedGroup" },
        { label: "List", value: "nestedList" },
      ],
    },
    {
      label: "Field Description",
      type: "textArea",
      variableName: "fieldDescription",
    },
  ];

  const [form, setForm] = useState(contentType === "block" ? form1 : form2);
  const listBlock = useSelector(listState)?.list;
  const listId = useSelector(listState)?.listId;
  const { pageId, siteId: websiteId } = useSelector(websiteState);
  const { error, loading } = useSelector(blockState);
  const [errorMessage, setErrorMessage] = useState("");
  const [input, setInput] = useState({});
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value } = e.target;

    let temp = { ...input };
    temp[name] = value;
    setInput({ ...temp });
  };

  const filterOptions = (options) => {
    return options.filter((obj) => {
      if (listBlock.nested || listBlock.blockType === "group") {
        return obj.value !== "nestedGroup" && obj.value !== "nestedList";
      }
      return obj;
    });
  };

  const handleClick = () => {
    if (contentType === "block") {
      if (!input.blockName || !input.blockType) {
        setErrorMessage("Block Name & Block Type Required");
        return;
      }
    } else {
      if (!input.fieldName || !input.fieldType) {
        setErrorMessage("Field Name & Field Type Required");

        return;
      }
    }

    const id = uuid();
    let temp = {
      ...input,
      id,
      blockData: input.blockType === "list" ? [] : {},
    };

    if (contentType === "block") {
      temp.blockName = temp?.blockName?.split(" ").join("_");
      temp.nested = false;
      // const tempBlocks = [...blocks];
      // tempBlocks.push({ ...temp });


      dispatch(createBlockFn({ data: temp, pageId, websiteId }));

      // dispatch(createBlockFn({ ...temp }));
    } else {
      const nestedId = uuid();

      temp.fieldName = temp?.fieldName?.split(" ").join("_");
      temp.blockData =
        temp.fieldType === "nestedList" || temp.fieldType === "nestedGroup"
          ? nestedId
          : {};
      if (listId === 0) {
        dispatch(
          createBlockFn({
            data: {
              data: {
                listId: uuid(),
                [id]: temp,
              },
              blockType: listBlock.blockType,
              blockId: listBlock.id,
              fieldId:
                temp.fieldType === "nestedList" ||
                  temp.fieldType === "nestedGroup"
                  ? temp.id
                  : "",
              type:
                temp.fieldType === "nestedList" ||
                  temp.fieldType === "nestedGroup"
                  ? temp.fieldType
                  : "",
              nestedId:
                temp.fieldType === "nestedList" ||
                  temp.fieldType === "nestedGroup"
                  ? nestedId
                  : "",
            },
            pageId,
            websiteId,
          })
        );
      } else {
        let obj = {
          data: {
            listId: !listId ? uuid() : listId,
            blockType: listBlock.blockType,
            fieldId: id,
            data: {
              [id]: temp,
            },
            type:
              temp.fieldType === "nestedList" ||
                temp.fieldType === "nestedGroup"
                ? temp.fieldType
                : "",
            nestedId:
              temp.fieldType === "nestedList" ||
                temp.fieldType === "nestedGroup"
                ? nestedId
                : "",
          },
          pageId,
          websiteId,
          blockId: listBlock.id,
        };
        dispatch(updateBlockFn(obj));
      }
    }
    if (!error && !loading) {
      handleLibrary();
    }
  };

  useEffect(() => {
    setForm(contentType === "block" ? form1 : form2);
    // eslint-disable-next-line
  }, [contentType]);

  return (
    <main className="block-library">
      <div className="block-library-content">
        <section className="site-heading-container">
          <h1>
            {contentType === "block" ? "Block Library" : listBlock?.blockName}
          </h1>
          <Button
            title={<CloseOutlined />}
            className="navigate-btn"
            onClick={handleLibrary}
          />
        </section>

        <form className="create-block-field">
          {form.map(({ label, type, variableName, options }, i) => {
            if (type === "text") {
              return (
                <div key={i} className="ind-create-block-field">
                  <label className="formLabel">{label}</label>
                  <TextField
                    value={input[variableName]}
                    onChange={handleChange}
                    name={variableName}
                  />
                </div>
              );
            } else if (type === "select") {
              return (
                <div key={i} className="ind-create-block-field">
                  <label className="formLabel">{label}</label>
                  <Dropdown
                    value={input[variableName]}
                    onChange={handleChange}
                    options={filterOptions(options)}
                    name={variableName}
                  />
                </div>
              );
            } else if (type === "textArea") {
              return (
                <div key={i} className="ind-create-block-field">
                  <label className="formLabel">{label}</label>
                  <CommentField
                    value={input[variableName]}
                    onChange={handleChange}
                    name={variableName}
                  />
                </div>
              );
            } else {
              return null;
            }
          })}
          {errorMessage ? <p className="block-err-msg">{errorMessage}</p> : ""}
          <Button
            title="Create"
            type="button"
            onClick={handleClick}
            className="create-block-btn"
            loading={loading}
          />
        </form>
      </div>
    </main>
  );
}
