import { DeleteOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { deleteFormResponse, getFormResponse } from "../../../Api/form";
import { NotifyUser } from "../../../Components/Notification";
import { Loader } from "../../../Components/Loader";
import { Dropdown } from "../../../Components/Dropdown";
import { TextField } from "../../../Components/TextField/index.js";
import { Paginate } from "../../../Components/Pagination";
import { dateFormatter } from "../../../utils/helperFn";

export function FormTable({ formData }) {
  const [formResponse, setFormResponse] = useState("");
  const [pagination, setPagination] = useState({});
  const { formId } = formData;
  const [filter, setFilter] = useState({ date: "", sort: "desc" });

  const sortOptions = [
    { label: "Ascending", value: "asc" },
    { label: "Descending", value: "desc" },
  ];

  const fetchFormResponse = async (query) => {
    const response = await getFormResponse({
      formId,
      query,
    });
    if (response.error) {
      NotifyUser({ type: "error", message: "error" });
      return;
    }
    setFormResponse(response.data);
    setPagination(response);
  };

  useEffect(() => {
    fetchFormResponse();
    // eslint-disable-next-line
  }, []);

  const handleDelete = async (id) => {
    const response = await deleteFormResponse({ formId, responseId: id });

    if (response.error) {
      NotifyUser({ type: "error", message: "Error Occurred" });
      return;
    } else {
      NotifyUser({ type: "success", message: "Form Response Deleted" });
      fetchFormResponse();
    }
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    let tempFilter = { ...filter };
    tempFilter[name] = value;
    setFilter(tempFilter);

    const date = dateFormatter({ format: "M/DD/YYYY", date: tempFilter.date });

    if (tempFilter.date) {
      fetchFormResponse(`?date=${date}&sort=${tempFilter.sort}`);
    } else {
      fetchFormResponse(`?sort=${tempFilter.sort}`);
    }
  };

  const headings =
    formResponse && formResponse[0] ? Object.keys(formResponse[0]) : [];
  if (formResponse) {
    headings.push("Action");
  }

  return (
    <main className="form-table">
      <section className="form-filter-container">
        <p>Filters</p>
        <TextField
          type="date"
          name="date"
          value={filter.date}
          onChange={handleChange}
        />
        <Dropdown
          options={sortOptions}
          name="sort"
          value={filter.sort}
          onChange={handleChange}
        />
      </section>

      {formResponse && formResponse.length ? (
        <div className="form-table-content">
          <section className="form-label-container">
            {headings && headings.length
              ? headings.map((item, i) => {
                if (item === "id" || item === "time") {
                  return null;
                }
                return (
                  <p className="ind-form-label form-label-heading" key={i}>
                    {item}
                  </p>
                );
              })
              : ""}
          </section>
          <section className="form-value-container">
            {formResponse && formResponse.length
              ? formResponse.map((item, index) => {
                return (
                  <div className="ind-form-value-container" key={index}>
                    {headings.map((heading, i) => {
                      if (heading === "id" || heading === "time") {
                        return null;
                      }

                      if (heading === "Action") {
                        return (
                          <button
                            className="ind-form-label"
                            onClick={() => handleDelete(item.id)}
                          >
                            <DeleteOutlined />
                          </button>
                        );
                      }

                      let value = item?.[heading] ? item?.[heading] : "";

                      return (
                        <p className="ind-form-label" title={value} key={i}>
                          {value}
                        </p>
                      );
                    })}

                    {/* {Object.entries(item).map(([key, value], i) => {
                        if (key === "id" || key === "time") {
                          return null;
                        }
                        return (
                          <p className="ind-form-label" title={value} key={i}>
                            {value}
                          </p>
                        );
                      })} */}
                  </div>
                );
              })
              : ""}
          </section>
        </div>
      ) : formResponse && !formResponse.length ? (
        <section className="form-no-response-container">No Response</section>
      ) : (
        <section className="form-no-response-container">
          <Loader />
        </section>
      )}

      {formResponse && formResponse.length ? (
        <section className="form-table-paginate-container">
          <Paginate
            className="formTable-paginate"
            setData={setFormResponse}
            pagination={pagination}
            total={formResponse.length}
            getDetails={fetchFormResponse}
          />
        </section>
      ) : (
        ""
      )}
    </main>
  );
}
