import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createBlock,
  deleteBlock,
  updateBlock,
  updateAllBlocks,
} from "../Api/block";
import { getPage } from "../Api/page";

const createBlockFn = createAsyncThunk(
  "data/createBlock",
  async (obj, thunkAPI) => {
    let response = await createBlock({ ...obj, type: "blocks" });
    return response?.blocks;
  }
);

const updateBlockFn = createAsyncThunk(
  "data/updateBlock",
  async (obj, thunkAPI) => {
    const response = await updateBlock({ ...obj, type: "blocks" });

    return response?.blocks;
  }
);

const saveBlockFn = createAsyncThunk(
  "data/saveBlock",
  async (obj, thunkAPI) => {
    const response = await updateAllBlocks({ ...obj, type: "blocks" });
    return response?.blocks;
  }
);

const deleteBlockFn = createAsyncThunk(
  "data/deleteBlock",
  async (obj, thunkAPI) => {
    const response = await deleteBlock({ ...obj, type: "blocks" });
    return response?.blocks;
  }
);

const getBlockFn = createAsyncThunk("data/getBlock", async (obj, thunkAPI) => {
  const response = await getPage(obj);
  return response?.blocks;
});

const blockSlice = createSlice({
  name: "Block",
  initialState: {
    error: false,
    loading: false,
    blocks: [],
  },
  reducers: {
    updateBlockContentFn: (state, action) => {
      const index = state.blocks.findIndex(
        (obj) => obj.id === action.payload.id
      );
      if (index >= 0) {
        state.blocks[index] = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createBlockFn.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createBlockFn.fulfilled, (state, action) => {
      state.loading = false;
      state.blocks = action.payload;
    });
    builder.addCase(createBlockFn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getBlockFn.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getBlockFn.fulfilled, (state, action) => {
      state.loading = false;
      state.blocks = action.payload;
    });
    builder.addCase(getBlockFn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(updateBlockFn.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateBlockFn.fulfilled, (state, action) => {
      state.loading = false;
      state.blocks = action.payload;
    });
    builder.addCase(updateBlockFn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(saveBlockFn.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(saveBlockFn.fulfilled, (state, action) => {
      state.loading = false;
      state.blocks = action.payload;
    });
    builder.addCase(saveBlockFn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteBlockFn.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteBlockFn.fulfilled, (state, action) => {
      state.loading = false;
      state.blocks = action.payload;
    });
    builder.addCase(deleteBlockFn.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

const blockReducer = blockSlice.reducer;
const blockState = (state) => state.blocks;
const { updateBlockContentFn } = blockSlice.actions;

export {
  blockReducer,
  blockState,
  createBlockFn,
  getBlockFn,
  updateBlockFn,
  updateBlockContentFn,
  deleteBlockFn,
  saveBlockFn,
};
