import { useState } from "react";
import { HiddenField, TextField } from "../../Components/TextField";
import { Button } from "../../Components/Button";
import { authApi } from "../../Api/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginFn } from "../../Redux/profileSlice";
import { useGoogleLogin } from "@react-oauth/google";
import "./auth.css";

const loginIllustration = "/loginIllustration.png";
const googleIcon = "/googleIcon.png";
const logo = "/logo.png";
const wave = "/wave.png";

export function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    let { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrorMessage("");
  };

  const handleBlur = (e) => {
    let { name, value, placeholder } = e.target;
    if (!value) {
      setErrorMessage(`${placeholder} is required`);
      setDisabled(true);
      return;
    }

    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        setErrorMessage("Invalid Email");
        return;
      }
    }

    if (name === "password") {
      const passwordRegex =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      if (!passwordRegex.test(value)) {
        setErrorMessage(
          "Password should have at least 8 characters, a letter, a number, and a special character"
        );
        setDisabled(true);

        return;
      }
    }
    setDisabled(false);
  };

  const handleSubmit = async () => {
    if (!formData.email) {
      setErrorMessage(`Email is required`);
      return;
    }
    if (!formData.password) {
      setErrorMessage(`Password is required`);
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setErrorMessage("Invalid Email");
      return;
    }

    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    if (!passwordRegex.test(formData.password)) {
      setErrorMessage(
        "Password should have at least 8 characters, a letter, a number, and a special character"
      );
      return;
    }
    setLoading(true);
    const { response, error, message } = await authApi({
      data: formData,
      type: "login",
    });
    setLoading(false);
    if (!error) {

      dispatch(loginFn({ ...response, isLoggedIn: true }));
      navigate("/site/view");
    } else {
      setErrorMessage(message);
    }
  };

  const googleLogin = async (token) => {
    if (!token?.access_token) {
      return;
    }

    const { response, error, message } = await authApi({
      data: { idToken: token?.access_token },
      type: "google-login",
    });
    if (!error) {
      dispatch(loginFn({ ...response, isLoggedIn: true }));
      navigate("/site/view");
    } else {
      setErrorMessage(message);
    }
  };

  return (
    <div className="login-page">
      <p className="auth-mobile-heading">Sign In</p>

      <div className="login-container">
        <div className="login-img-container">
          {" "}
          <img src={logo} className="auth-login-logo" alt="logo" />
          <img
            src={loginIllustration}
            className="login-illustration-img"
            alt="illustration"
          />
        </div>

        <div className="login-content-container">
          <div>
            <p className="login-heading">Sign In</p>
            {/* <p className="login-description">
              Lorem ipsum dolor sit amet elit. Sapiente sit aut eos consectetur
              adipisicing.
            </p> */}
          </div>

          <div className="login-field-container">
            <TextField
              name="email"
              onChange={handleChange}
              value={formData.email}
              className="login-field-email"
              placeholder="Email"
              // onBlur={handleBlur}
              autoFocus={true}
            />
            <div className="field-divider" />
            <HiddenField
              name="password"
              onChange={handleChange}
              value={formData.password}
              className="login-field-password"
              placeholder="Password"
            // onBlur={handleBlur}
            />
            <p className="login-err-message">{errorMessage}</p>
          </div>

          <div className="login-checkbox-container">
            <Button
              title="Don't have Account?"
              onClick={() => navigate("/signup")}
              className="forgot-password-btn"
            />
            <Button
              onClick={() => navigate("/forgot-password")}
              title="Forgot Password"
              className="forgot-password-login-btn"
            />
          </div>
          <Button
            title="Login In"
            // disabled={disabled}
            className="login-btn"
            loading={loading}
            onClick={handleSubmit}
          />
          <div className="social-login-container">
            <p>— or login with —</p>
            <button
              onClick={useGoogleLogin({
                onSuccess: googleLogin,
                onError: () => {
                  setErrorMessage("Google Login Failed");
                },
              })}
            >
              <img src={googleIcon} className="google-login-img" alt="google" />{" "}
            </button>
          </div>
        </div>
      </div>
      <img src={wave} className="login-wave-img" alt="wave" />
    </div>
  );
}
