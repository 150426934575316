import axios from "axios";
import { getItem } from "../utils/helperFn";

const base_url = process.env.REACT_APP_HOST_URL;

const createPage = async ({ data, websiteId }) => {
  try {
    await axios({
      url: `${base_url}/page?websiteId=${websiteId}`,
      method: "POST",
      data,
      headers: { Authorization: getItem("token") },
    });

    return {};
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const updatePage = async ({ data, websiteId, pageId }) => {
  try {
    await axios({
      url: `${base_url}/page/${pageId}?websiteId=${websiteId}`,
      method: "PUT",
      data,
      headers: { Authorization: getItem("token") },
    });

    return {};
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getAllPages = async (id) => {
  try {
    const response = await axios({
      url: `${base_url}/page?websiteId=${id}`,
      method: "GET",
      headers: { Authorization: getItem("token") },
    });
    return response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const getPage = async ({ pageId, websiteId, type }) => {
  try {
    const response = await axios({
      url: `${base_url}/page/${pageId}?websiteId=${websiteId}&type=${type}`,
      method: "GET",
      headers: {
        Authorization: getItem("token"),
      },
    });
    return type ? response.data : response?.data?.data;
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const deletePage = async ({ pageId, websiteId }) => {
  try {
    await axios({
      url: `${base_url}/page/${pageId}?websiteId=${websiteId}`,
      method: "DELETE",
      headers: { Authorization: getItem("token") },
    });

    return {};
  } catch (error) {
    return { error: true, message: error.response.data.message };
  }
};

const handlePreview = ({ websiteId, pageId }) => {
  const redirect_url = `${base_url}/editor?website=${websiteId}&page=${pageId}&token=${getItem(
    "token"
  )}`;

  window.open(redirect_url, "_blank");
};

export {
  createPage,
  getAllPages,
  getPage,
  deletePage,
  updatePage,
  handlePreview,
};
